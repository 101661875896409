import React, { FC, useState, useEffect, useRef } from "react";
import Spinner from "components/Shared/Spinner/Spinner";
import NoItemsPage from "components/Shared/NoItemsPage/NoItemsPage";
import Filter from "components/Shared/Filter/Filter";
import useSpinnerAction from "utils/hooks/useSpinnerAction";

interface IProps {
  children: any;
  showSpinner?: boolean;
  itemsCount: number;
}

const ListWrapper: FC<IProps> = ({ children, showSpinner, itemsCount }) => {
  const [showList, setShowList] = useState<boolean>(false);
  const filters = React.Children?.map(children, (child) =>
    child.type === ListFilters ? child : null
  );
  const search = React.Children?.map(children, (child) =>
    child.type === ListSearch ? child : null
  );
  const list = React.Children?.map(children, (child) => (child.type === List ? child : null));

  const filterProps = filters && filters[0] && filters[0].props;

  const checkActionHeaderRender = () => {
    return !!filters.length || !!search.length;
  };
  useSpinnerAction(showSpinner, () => setShowList(true));
  return (
    <div>
      {checkActionHeaderRender() && (
        <div className="list-wrapper-actions-header">
          {filterProps && <Filter filters={filterProps.filters} onChange={filterProps.onChange} />}
          {search}
        </div>
      )}
      {showSpinner ? (
        <Spinner showSpinner={showSpinner} />
      ) : !showList ? (
        <NoItemsPage />
      ) : (
        <div className={`content-section-container ${showList ? "show" : "hidden"}`}>
          <div className="section-list">{list}</div>
        </div>
      )}
    </div>
  );
};
export const ListFilters = (props) => props;

export const ListSearch = ({ children }) => children;

export const List = ({ children }) => children;

export default ListWrapper;
