import Icon from "components/Shared/Icons/Icon/Icon";
import SubjectIcon from "components/Shared/SubjectIcon/SubjectIcon";
import moment from "moment";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { makeSelectActiveCourse } from "Store/user/selectors";
import colors, { ICONS_COLORS } from "constants/colors";
import { capitalizeFirstLetter, genClasses } from "utils/textHelpers";
import ToolTip from "components/Shared/ToolTip/ToolTip";
import { sortedSubjectsByCourse } from "constants/contextMenus";
import { TYPE_ICONS } from "constants/icons";

interface IProps {
  title: string;
  status: string;
  date?: string;
  onClick?: (event: any) => void;
  today?: boolean;
  sections: React.ReactNode;
  version?: string;
  item: any;
}

const TaskListItem: FC<IProps> = ({
  title,
  version,
  status,
  date,
  sections,
  onClick,
  today,
  item
}) => {
  const overdue = status === "OVERDUE";
  const assigned = status === "ASSIGNED";
  const subjects = useSelector(makeSelectActiveCourse);
  const activeCourse = useSelector(makeSelectActiveCourse);
  const getType = () => {
    if (item.type === "test" || item.hasOwnProperty("test_id")) {
      return "test";
    } else if (item.type === "worksheet" || item.hasOwnProperty("worksheet_id")) {
      return "worksheet";
    } else {
      return item?.subject_name?.toLowerCase();
    }
  };
  const displayStatus = () => {
    return status === "COMPLETED"
      ? `Completed ${moment(item?.completion_date || item?.completed_at).format(
          "MM/DD/YYYY"
        )} ${checkLateness()}`
      : status?.toLocaleLowerCase();
  };

  const checkLateness = () => {
    if (!item?.due_date) return "";
    const completedAt = item?.completion_date || item?.completed_at;
    const late = moment(completedAt).isAfter(item.due_date) ? "late" : false;
    if (!late) return "";
    return item?.lateness_excused
      ? `<span style={{color: colors.grey}}>(${late}, excused)</span>`
      : `<span style={{color: colors.red}}>(${late})</span>`;
  };

  const unavailable = () => {
    const date = moment().utc();
    return moment(item?.assignment_date).utc().isAfter(date);
  };

  const getStatusColorClass = () => {
    if (unavailable()) {
      return "item-unavailable";
    } else if (today || (today && overdue)) {
      return "today";
    } else if (status === "ASSIGNED" || status === "STARTED") {
      return "light";
    } else if (overdue) {
      return "overdue";
    }
  };
  const getDescription = () => {
    if (getType() === "test" && (item?.description || item?.test?.description)) {
      return (
        <div style={{ marginLeft: 10, color: colors.grey }}>
          <div>Version: {item?.description || item?.test?.description}</div>
        </div>
      );
    }
  };

  const renderSections = () => {
    if (!item?.sections) return null;
    const sect = item.sections?.map((s) => ({
      name: (s.section?.name || s.name).toLowerCase(),
      status: (s.test_section_status === "CREATED"
        ? "ASSIGNED"
        : s.test_section_status
      ).toLowerCase()
    }));

    const sortedSections = sortedSubjectsByCourse[activeCourse.course_type]?.map(
      (sortedSection) => {
        const section = sect.find((section) => section.name === sortedSection);
        return !Boolean(section)
          ? {
              name: sortedSection,
              status: "unassigned"
            }
          : section;
      }
    );

    return (
      <div className="centered-row" style={{ marginLeft: 10 }}>
        {sortedSections?.map((s, index) => (
          <ToolTip
            tips={`${capitalizeFirstLetter(s.name)} (${capitalizeFirstLetter(s.status)})`}
            onLeft
            style={{ minWidth: 150 }}
            key={`section-${index}`}
          >
            <Icon
              icon={TYPE_ICONS[s.name]}
              className="test-section-icon"
              color={ICONS_COLORS[s.status]}
            />
            {s.status === "started" && <div className="green-dot-marker" />}
          </ToolTip>
        ))}
      </div>
    );
  };

  return (
    <div className="task-container">
      <div
        className={`task-left-bar ${getStatusColorClass()}`}
        onClick={onClick ? onClick : null}
      />
      <div className="task-content-container">
        <div className="task-content">
          <div
            className="task-title-container"
            onClick={(event) => (!unavailable() && onClick ? onClick(event) : null)}
          >
            <SubjectIcon itemType={getType()} />
            <div>
              <h3 className="task-title">
                {title} {getType() === "test" && renderSections()}
              </h3>
              {getDescription()}
            </div>
          </div>
          {version && (
            <div className="task-version">
              Version: <p>{version}</p>
            </div>
          )}
          <div
            className={genClasses("task-status-container", {
              "task-status-container--today": today
            })}
          >
            {status === "ASSIGNED" && <span className="task-status-icon ico-mark" />}
            <div
              className={genClasses("task-status", {
                overdue,
                today: today,
                assigned: assigned
              })}
              dangerouslySetInnerHTML={{ __html: displayStatus() }}
            />
            {date && (
              <>
                <Icon
                  icon="date-range"
                  className={genClasses("task-icon icon-calendar", { overdue })}
                  color={colors.black}
                />
                <div className={genClasses("task-date", { overdue })}>
                  {date ? moment(date).format(today ? "MM/DD HH:mm" : "MM/DD") : " "}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="task-buttons">{sections}</div>
      </div>
    </div>
  );
};

export default TaskListItem;
