export const SubjectIcons:Record<SubjectIconKey, SubjectIcon> = {
    "math":{
        icon: "math",
        backgroundColor:"#f8962d"
    },
    "reading":{
        icon: "reading",
        backgroundColor:"#f8962d"
    },
    "writing and language":{
        icon: "writing",
        backgroundColor:"#f8962d"
    },
    "science":{
        icon: "science",
        backgroundColor:"#f8962d"
    },
    "english":{
        icon: "writing",
        backgroundColor:"#f8962d"
    },
    "worksheet":{
        icon: "clipboard-list-check-solid",
        backgroundColor:"#405A9C"
    },
    "test":{
        icon: "ballot-check",
        backgroundColor:"#AB48AF"
    },
    "lesson":{
        icon: "ballot-check",
        backgroundColor:"#AB48AF"
    }
};
