import React, { FC, CSSProperties } from "react";
import Icon from "components/Shared/Icons/Icon/Icon";
import colors from "constants/colors";

interface IProps {
  style?: CSSProperties;
  small?: boolean;
}

const NoItemsPage: FC<IProps> = ({ style, small }) => {
  const size = small ? 30 : 100;
  return (
    <div className="no-items-page center-text" style={{ textAlign: "center" }}>
      <Icon
        icon="empty-set"
        style={{ width: size, height: size, ...style }}
        color={colors.lightGrey}
      />
      <h2>There are no items to display.</h2>
    </div>
  );
};

export default NoItemsPage;
