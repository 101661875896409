import React, { FC } from "react";
import ReactDOM from "react-dom";
import colors from "constants/colors";
import Icon from "components/Shared/Icons/Icon/Icon";
import Button from "components/Shared/Button/Button";
import ClickOffComponentWrapper from "components/Shared/ClickOffComponentWrapper/ClickOffComponentWrapper";
import Select from "react-select";

interface IProps {
  filters: Filter[];
  onClose: () => void;
  onChange: (filterId: string, value: string) => void;
}

const customStyles = {
  option: (styles) => ({
    ...styles,
    cursor: "pointer"
  }),
  control: (styles) => ({
    ...styles,
    cursor: "pointer"
  })
};
/* @TODO: This is very basic version, for admin app needs to be rebuild and the type changed to array of filters as onChange value*/
const FiltersModal: FC<IProps> = ({ filters, onClose, onChange }) => {
  const handleChange = (filterItem: FilterItems, filterId: string) => {
    onChange(filterId, filterItem.value);
  };

  const getSelectedItem = (filter: Filter): FilterItems =>
    filter.items.find((item) => item.selected);

  return ReactDOM.createPortal(
    <div className="filter-modal-container">
      <ClickOffComponentWrapper onOuterClick={onClose}>
        <div className="filter-modal-content">
          <div
            className="modal-header"
            style={{
              background: colors.studentBlue
            }}
          >
            <div className="modal-header-items">
              <Icon
                icon="filter"
                style={{
                  marginRight: "10px"
                }}
              />
              <div>Select filters</div>
            </div>
            <Icon
              icon="close"
              style={{
                cursor: "pointer"
              }}
              onClick={onClose}
            />
          </div>
          <div className="modal-body">
            {filters?.map((filter: Filter) => (
              <div key={filter.id}>
                <label className="filters-filter-label">{filter.label}</label>
                <Select
                  styles={customStyles}
                  options={filter.items}
                  value={getSelectedItem(filter)}
                  className="filter-select"
                  id={filter.id}
                  onChange={(filterItem: FilterItems) => handleChange(filterItem, filter.id)}
                />
              </div>
            ))}
          </div>
          <div className="modal-button-container">
            <Button name="Close" onClick={onClose} icon="close" />
            <Button name="View Results" onClick={onClose} primary />
          </div>
        </div>
      </ClickOffComponentWrapper>
    </div>,
    document.body
  );
};

export default FiltersModal;
