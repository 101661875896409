import React, { CSSProperties, FC, ReactNode } from "react";
import { genClasses } from "utils/textHelpers";

interface IProps {
  tips: string | React.ReactNode;
  row?: boolean;
  top?: boolean;
  left?: boolean;
  style?: CSSProperties;
  className?: string;
  onLeft?: boolean;
  right?: boolean;
  white?: boolean;
  children: ReactNode;
}

const ToolTip: FC<IProps> = ({
  children,
  tips,
  row,
  top,
  style,
  className,
  left,
  onLeft,
  right,
  white
}) => {
  return (
    <div className={`tooltip-container ${className || ""}`}>
      {children}
      <div
        className={genClasses("tooltip-bubble", {
          row: row,
          top: top,
          left: left,
          "whole-left": onLeft,
          right: right,
          white: white
        })}
        style={style}
        dangerouslySetInnerHTML={typeof tips === "string" ? { __html: tips } : null}
      >
        {typeof tips !== "string" ? tips : null}
      </div>
    </div>
  );
};

export default ToolTip;
