import { useEffect, useRef } from "react";

const useSpinnerAction = (isLoading: boolean, callback: () => void) => {
  const loaderRef = useRef(null);

  useEffect(() => {
    if (loaderRef.current && !isLoading) {
      callback();
    }
    loaderRef.current = isLoading;
  }, [isLoading]);
};

export default useSpinnerAction;
