import { SubjectIcons } from "constants/subjectIcon";
import React, { FC } from "react";
import Icon from "components/Shared/Icons/Icon/Icon";

interface IProps {
  itemType: string;
}

const SubjectIcon: FC<IProps> = ({ itemType }) => {
  const item: SubjectIcon = SubjectIcons[itemType];

  return (
    <span className="task-icon-wrapper" style={{ backgroundColor: item?.backgroundColor }}>
      <Icon icon={item?.icon || "bar"} className="task-icon" />
    </span>
  );
};

export default SubjectIcon;
