export const TYPE_ICONS = {
  test: "ballot-check",
  session: "date-range",
  lesson: "book-solid",
  worksheet: "clipboard-list-check-solid",
  "writing and language": "writing",
  "reading & writing": "reading",
  reading: "reading",
  english: "writing",
  math: "math",
  science: "science",
  "math (no calculator)": "math",
  "math (calculator)": "calculator",
  writing: "writing"
};
