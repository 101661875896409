import React, { FC, useState } from "react";
import FiltersModal from "components/Shared/Modals/FiltersModal/FiltersModal";
import Button from "components/Shared/Button/Button";

interface IProps {
  filters: any[];
  onChange: (filter: string) => void;
}

const Filter: FC<IProps> = ({ filters, onChange }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Button name="Show Filters" onClick={() => setOpenModal(!openModal)} blue icon="filter" />
      {openModal && (
        <FiltersModal
          onChange={onChange}
          filters={filters}
          onClose={() => setOpenModal(!openModal)}
        />
      )}
    </>
  );
};

export default Filter;
