import React, { FC, useRef, useEffect } from "react";

interface IProps {
  children: React.ReactNode;
  onOuterClick: () => void;
  className?: string;
  nestedModals?: boolean;
}

const ClickOffComponentWrapper: FC<IProps> = ({
  children,
  onOuterClick,
  className,
  nestedModals
}) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current?.contains(event.target)) {
      if (nestedModals) {
        return;
      }
      onOuterClick();
    }
  };

  return (
    <div className={className} ref={wrapperRef}>
      {children}
    </div>
  );
};
export default ClickOffComponentWrapper;
