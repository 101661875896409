export const sortedSubjectsByCourse = {
  ACT: ["english", "math", "reading", "science"],
  SAT: ["reading", "writing", "math (no calculator)", "math (calculator)"],
  dSAT: ["reading & writing", "math"]
};

export const sortedSubjectScores = {
  ACT: ["english", "math", "reading", "science"],
  SAT: ["reading", "reading & writing", "writing and language", "math"],
  dSAT: ["reading & writing", "math"]
};

export const sortedSubjectScoresShort = {
  ACT: ["english", "math", "reading", "science"],
  SAT: ["reading", "writing", "math"],
  dSAT: ["reading & writing", "math"]
};

export const sortedSectionsByCourse = {
  ACT: ["english", "math", "reading", "science"],
  dACT: ["english", "math", "reading", "science"],
  SAT: ["reading", "writing", "math (no calculator)", "math (calculator)"],
  dSAT: ["reading & writing", "math"]
};